import { FC } from 'react';
import { UseFormReturnType } from '@mantine/form';
import { Button, Checkbox, LoadingOverlay } from '@mantine/core';
import Link from 'next/link';
import cn from 'classnames';
import { SliceBeautifulNumber } from '@/store/slice/beautiful-number';
import { LINKS } from '@/shared/constants/links';
import { ShoppingCart } from '@/shared/assets/svg';
import { formatPrice } from '@/shared/lib/utils';
import { AgreementLink } from '@/shared/ui-kit';
import { FormInitialValues } from './constants';
import styles from './form.module.scss';

interface FormFooterProps {
  selectedNumber: SliceBeautifulNumber | null;
  isPromotionExpired: boolean;
  form: UseFormReturnType<FormInitialValues>;
  handler?: (form: UseFormReturnType<FormInitialValues>) => void;
  disabled?: boolean;
  requestIsLoading?: boolean;
  isSmsLoading: boolean;
  totalPrice: number;
}

export const FormFooter: FC<FormFooterProps> = ({
  selectedNumber,
  isPromotionExpired,
  form,
  handler,
  disabled,
  requestIsLoading,
  isSmsLoading,
  totalPrice,
}) => {
  return (
  <>
    {selectedNumber && !isPromotionExpired && (
      <div className={styles.confirm__checkbox}>
        <Checkbox
          className={styles.checkbox}
          checked={form.values.acceptPromotion}
          onClick={() => form.setFieldValue('acceptPromotion', !form.values.acceptPromotion)}
        />
        <span className={styles.promotionTitle}>
          Я принимаю{' '}
          <Link href={LINKS.BEAUTIFUL_NUMBERS_BLACK_FRIDAY_FILE} target='_blank' className={styles.link}>
            условия акции «Скидка на красивый номер»
          </Link>
        </span>
      </div>
    )}
    <div className={styles.confirm__footer}>
      <Button
        type='submit'
        onClick={() => handler && handler(form as UseFormReturnType<FormInitialValues>)}
        className={cn(styles.button, styles.button__submit)}
        classNames={{ icon: styles.button__icon }}
        disabled={
          !!disabled ||
          requestIsLoading ||
          isSmsLoading ||
          (selectedNumber ? !isPromotionExpired && !form.values.acceptPromotion : false)
        }
        leftIcon={!requestIsLoading && !isSmsLoading && <ShoppingCart />}
      >
        {requestIsLoading || isSmsLoading ? (
          <div className={styles.loading}>
            <LoadingOverlay visible loaderProps={{ color: 'white' }} />
          </div>
        ) : (
          <span>Оформить заказ {formatPrice(totalPrice)} </span>
        )}
      </Button>
      <AgreementLink
        text='Нажимая «Оформить заказ», вы принимаете условия обработки персональных данных'
        linkText='условия обработки персональных данных'
      />
    </div>
  </>
  );
};

import { FC, ReactNode, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Accordion, Modal, ScrollArea } from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import Cookies from 'js-cookie';
import { isAfter } from 'date-fns';
import cn from 'classnames';
import { useRecordReferralTrackMutation } from '@/store/api/referral-system/referral-system.api';
import { useAppSelector } from '@/store/hooks';
import { AccordionItem, DateTimePicker } from '@/shared/ui-kit/form';
import { ItemStatus } from '@/shared/ui-kit/form/accordion';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { decodeInviteCode } from '@/shared/lib/utils';
import { toggleWidgetVisibility } from '@/shared/lib/chat2desk-utils';
import { ArrowDownIcon, CloseIcon } from '@/shared/assets/svg';
import { blackFridayDeadline } from '@/shared/constants/black-friday';
import { reachGoal } from '@/shared/lib/ym';
import { YM } from '@/shared/constants/yandex-metrika';
import { CONSTANTS, Sections, initialDate, SECTIONS_NAMES, FormInitialValues, initialValues } from './constants';
import { ConfirmationSection, DeliverySection, InfoSection, SmsSection } from './sections';
import { FormFooter } from './footer';
import styles from './form.module.scss';

interface FormProps {
  isOpened: boolean;
  onClose: () => void;
  totalPrice: number;
  confirmPanel: ReactNode;
  header?: ReactNode;
  withHI?: boolean;
  handler?: (form: UseFormReturnType<FormInitialValues>) => void;
  requestIsLoading?: boolean;
  requestIsSuccess?: boolean;
  withoutSms?: boolean;
}

const Form: FC<FormProps> = memo(
  ({ withHI, totalPrice, isOpened, onClose, confirmPanel, header, handler, requestIsLoading, withoutSms, requestIsSuccess }) => {
    const [openedSection, setOpenedSection] = useState<string>('');
    const [deliveryStatus, setDeliveryStatus] = useState<ItemStatus>();
    // const [paymentStatus, setPaymentStatus] = useState<ItemStatus>();
    const [infoStatus, setInfoStatus] = useState<ItemStatus>();
    const [confirmationStatus, setConfirmationStatus] = useState<ItemStatus>();
    const [smsStatus, setSmsStatus] = useState<ItemStatus>();
    const { isMobile } = useMediaQuery();
    const [recordReferralTrack] = useRecordReferralTrackMutation();
    const [date, setDate] = useState<Date>(initialDate());
    const inputRef = useRef<HTMLInputElement>(null);
    const [disabled, setDisabled] = useState(true);
    const [opened, { open, close }] = useDisclosure(false);

    const isSmsLoading = useAppSelector((state) => state.app.isSmsLoading);
    const { selectedNumber, pinConfirmedNumber } = useAppSelector((state) => state.beautifulNumber);
    const inviteCode = Cookies.get('inviteCode');
    const whoRecommended = inviteCode ? decodeInviteCode(inviteCode) : '';
    const blackFridayCheckboxDeadline = new Date(blackFridayDeadline);
    const isPromotionExpired = isAfter(new Date(), blackFridayCheckboxDeadline);
    const initialOpenedSection = useMemo(() => 
      withHI ? SECTIONS_NAMES[Sections.info] : SECTIONS_NAMES[Sections.delivery],
    [withHI]);

    useEffect(() => {
      if (withoutSms) {
        setDisabled(false);
      }
    }, [withoutSms]);

    const form = useForm({
      initialValues,

      validate: {
        delivery: (val) => (withHI ? null : val === '' ? '' : null),
        // payment: (val) => (withHI ? null : val === '' ? '' : null),
        info: {
          name: (val) => (val.length < 3 || /\d/.test(val) ? CONSTANTS.validationLabels.name : null),
          contact_phone: (val) =>
            !val
              ? CONSTANTS.validationLabels.phone
              : val.length === 10
                ? null
                : CONSTANTS.validationLabels.invalidPhone,
          email: (val) => {
            if (!val) {
              return null;
            } else {
              return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(val) ? null : CONSTANTS.validationLabels.email;
            }
          },
          address: (val) => {
            if (
              ((form.values.delivery === 'true' || withHI) && val === '') ||
              (val && val.length < 3 && form.values.delivery === 'true')
            )
              return CONSTANTS.validationLabels.address;

            return null;
          },
          has_citizenship: (val) => (withHI ? null : val ? null : CONSTANTS.validationLabels.required),
          exactTime: () => null,
          date: () => null,
          point: (val) => {
            if (form.values.delivery === 'false' && val === '' && !withHI) return CONSTANTS.validationLabels.point;

            return null;
          },
          whoRecommended: () => null,
        },
        confirmation: (val) => (val ? null : ''),
        acceptPromotion: (val) => (val ? null : ''),
      },
    });

    useEffect(() => {
      if (requestIsSuccess) {
        form.reset();
      }
      setOpenedSection(initialOpenedSection);
    }, [requestIsSuccess, initialOpenedSection]);

    useEffect(() => {
      if (whoRecommended) {
        form.setFieldValue('info.whoRecommended', whoRecommended);
        recordReferralTrack({ msisdn: whoRecommended }); // Запись о переходе по реферальной ссылке
      }
    }, [whoRecommended]);

    useEffect(() => {
      if (openedSection === SECTIONS_NAMES[Sections.delivery]) {
        setDeliveryStatus(ItemStatus.IN_PROGRESS);
      } else {
        form.isValid(SECTIONS_NAMES[Sections.delivery])
          ? setDeliveryStatus(ItemStatus.FILLED)
          : setDeliveryStatus(ItemStatus.DISABLED);
      }
    }, [form, form.values.delivery, openedSection]);

    const isPinCodeActivate = selectedNumber?.phone_id === pinConfirmedNumber;
    // useEffect(() => {
    //   if (openedSection === SECTIONS_NAMES[Sections.payment]) {
    //     setPaymentStatus(ItemStatus.IN_PROGRESS);
    //   } else {
    //     form.isValid(SECTIONS_NAMES[Sections.payment])
    //       ? setPaymentStatus(ItemStatus.FILLED)
    //       : setPaymentStatus(ItemStatus.DISABLED);
    //   }
    // }, [form, form.values.payment, openedSection]);

    useEffect(() => {
      if (openedSection === SECTIONS_NAMES[Sections.info]) {
        setInfoStatus(ItemStatus.IN_PROGRESS);
      } else {
        form.isValid(SECTIONS_NAMES[Sections.info])
          ? setInfoStatus(ItemStatus.FILLED)
          : setInfoStatus(ItemStatus.DISABLED);
      }
    }, [form, form.values.info, openedSection]);

    useEffect(() => {
      if (openedSection === SECTIONS_NAMES[Sections.confirmation]) {
        setConfirmationStatus(ItemStatus.IN_PROGRESS);
      } else {
        form.values.confirmation === true
          ? setConfirmationStatus(ItemStatus.FILLED)
          : setConfirmationStatus(ItemStatus.DISABLED);
      }
      if (openedSection === SECTIONS_NAMES[Sections.sms]) {
        setSmsStatus(ItemStatus.IN_PROGRESS);
      } else {
        setSmsStatus(ItemStatus.DISABLED);
      }
    }, [form.values.confirmation, openedSection]);

    useEffect(() => {
      if (form.values.info.exactTime === 'true') {
        open();
      }
    }, [form.values.info.exactTime, open]);

    useEffect(() => {
      if (form.values.delivery !== '') {
        if (form.values.delivery === 'true') {
          reachGoal(YM.click_cart_dostavka);
        } else if (form.values.delivery === 'false') {
          reachGoal(YM.click_cart_salon);
        }
        setOpenedSection(SECTIONS_NAMES[Sections.info]);
      }
      // form.values.delivery !== '' &&
      // && setOpenedSection(SECTIONS_NAMES[Sections.payment]);
      // form.values.payment !== ''
    }, [form.values.delivery /*, form.values.payment*/]);

    const onAccordionChange = (val: string) => {
      if (Sections[openedSection as keyof typeof Sections] > Sections[val as keyof typeof Sections]) {
        setOpenedSection(val);
      } else {
        let shouldOpen = true;
        for (const section in Sections) {
          if (
            Sections[val as keyof typeof Sections] > Sections[section as keyof typeof Sections] &&
            !form.isValid(section)
          ) {
            shouldOpen = false; //проверка валидности предыдущих пунктов
          }
        }
        shouldOpen ? setOpenedSection(val) : form.validateField(openedSection);
      }
    };

    const dataHandler = (d: Date) => {
      setDate(d);
      form.setFieldValue('info.date', d);
    };

    const footer = (
      <FormFooter
        selectedNumber={selectedNumber}
        isPromotionExpired={isPromotionExpired}
        form={form}
        handler={handler}
        disabled={disabled}
        requestIsLoading={requestIsLoading}
        isSmsLoading={isSmsLoading}
        totalPrice={totalPrice}
      />
    );

    const accordionData = [
      DeliverySection({ form, status: deliveryStatus }),
      // PaymentSection({ form, status: paymentStatus }),
      InfoSection({
        form,
        status: infoStatus,
        withHI,
        inputRef,
        whoRecommended,
        open,
        setOpenedSection
      }),
      ConfirmationSection({
        form,
        status: confirmationStatus, 
        withHI,
        withoutSms,
        confirmPanel,
        setOpenedSection,
        footer
      }),
      SmsSection({
        form,
        status: smsStatus,
        footer,
        openedSection,
        disabled,
        setOpenedSection,
        setDisabled,
        inputRef
      })
    ];

    useEffect(() => {
      if (isOpened) {
        toggleWidgetVisibility(false);
      } else {
        toggleWidgetVisibility(true);
      }
    }, [isOpened]);

    return (
      <Modal
        opened={isOpened}
        onClose={onClose}
        centered
        classNames={{
          root: styles.modal__root,
          inner: styles.modal__inner,
          header: styles.modal__header,
          body: styles.modal__body,
          content: styles.modal__content,
        }}
      >
        <Box
          component='form'
          onSubmit={form.onSubmit(() => {
            // console.log(form.values, selectedTariffAndServices);
          })}
          className={cn(styles.container)}
        >
          <CloseIcon className={styles.close} onClick={onClose} />
          {header}
          <ScrollArea.Autosize
            w='calc(100% + 8px)'
            mah={isMobile ? 'calc(100vh - 180px)' : 'calc(100vh - 258px)'}
            type='auto'
            scrollbarSize='8px'
            offsetScrollbars
            classNames={{
              scrollbar: styles.scroll__bar,
              thumb: styles.scroll__thumb,
              viewport: styles.scroll__viewport,
            }}
          >
            <Accordion
              classNames={{
                control: styles.control,
                chevron: styles.control__chevron,
                label: styles.control__label,
                item: styles.item,
                content: styles.content,
              }}
              value={openedSection}
              onChange={(val) => val && onAccordionChange(val)}
              chevron={<ArrowDownIcon />}
              transitionDuration={500}
            >
              {accordionData
                .filter(({ internet }) => (withHI ? internet : true))
                .filter((item) => (withoutSms ? item.value !== SECTIONS_NAMES[Sections.sms] : true))
                .map((item, idx) => (
                  <AccordionItem {...item} key={idx} idx={idx} />
                ))}
            </Accordion>
          </ScrollArea.Autosize>
          {form.values.info.exactTime === 'true' && (
            <DateTimePicker date={date} handler={dataHandler} opened={opened} close={close} />
          )}
        </Box>
      </Modal>
    );
  },
);

Form.displayName = 'Form';
export { Form };

import { ReactNode, RefObject } from 'react';
import { SmsPanel } from '@/shared/ui-kit/form';
import { CONSTANTS, SectionDataType, SectionProps, Sections, SECTIONS_NAMES } from '../constants';

interface SmsSectionProps extends SectionProps {
  footer: ReactNode;
  openedSection: string;
  disabled: boolean;
  setDisabled: (val: boolean) => void;
  inputRef: RefObject<HTMLInputElement>
}

export const SmsSection = ({
  form,
  status,
  footer,
  openedSection,
  disabled,
  setDisabled,
  setOpenedSection,
  inputRef
}: SmsSectionProps): SectionDataType => {
  const changeContactPhone = () => {
    setOpenedSection?.(SECTIONS_NAMES[Sections.info]);
    inputRef.current && inputRef.current.dispatchEvent(new Event('focus'));
    inputRef.current && inputRef.current.focus();
  };

  return {
    status,
    internet: true,
    value: SECTIONS_NAMES[Sections.sms],
    control: CONSTANTS.sms.control,
    panel: (
      <SmsPanel
        contact_phone={form.values.info.contact_phone}
        footer={footer}
        opened={openedSection === SECTIONS_NAMES[Sections.sms]}
        disabled={disabled}
        changeContactPhone={changeContactPhone}
        setDisabled={setDisabled}
      />
    )
  }
};
import { RefObject } from 'react';
import cn from 'classnames';
import { Control, EmailInput, RadioGroup, SelectedPoint, TextInput } from '@/shared/ui-kit/form';
import { ItemStatus } from '@/shared/ui-kit/form/accordion';
import { PhoneInput, RichText, Tooltip } from '@/shared/ui-kit';
import Info from '@/shared/assets/svg/info-small.svg';
import { Button, Checkbox } from '@mantine/core';
import { addHours, format } from 'date-fns';
import { TimeIcon } from '@/shared/assets/svg';
import { SalesPointsMap } from '@/widgets/sales-point-map';
import { Point } from '@/shared/ui-kit/map/map.types';
import { reachGoal } from '@/shared/lib/ym';
import { YM } from '@/shared/constants/yandex-metrika';
import { CONSTANTS, SectionDataType, SectionProps, Sections, SECTIONS_NAMES } from '../constants';
import styles from '../form.module.scss';

interface InfoSectionProps extends SectionProps {
  inputRef: RefObject<HTMLInputElement>;
  whoRecommended: string;
  open: () => void;
}

export const InfoSection = ({
  form,
  status,
  withHI,
  inputRef,
  whoRecommended,
  open,
  setOpenedSection
}: InfoSectionProps): SectionDataType => {
  const setAddress = (s: Point) => {
    const formattedTime = s?.time.replace('-', ' до ');

    return form.setFieldValue('info.point', `${s?.fullAddress} c ${formattedTime}`);
  };

  const next = () => {
    if (form.isValid(SECTIONS_NAMES[Sections.info])) {
      setOpenedSection?.(SECTIONS_NAMES[Sections.confirmation]);
      reachGoal(YM.click_cart_next_step2);
    } else {
      (!form.isValid('info.name') && form.validateField('info.name')) ||
        (!form.isValid('info.contact_phone') && form.validateField('info.contact_phone')) ||
        (!form.isValid('info.has_citizenship') && form.validateField('info.has_citizenship')) ||
        (!form.isValid('info.email') && form.validateField('info.email')) ||
        (!form.isValid('info.address') && form.validateField('info.address')) ||
        (!form.isValid('info.point') && form.validateField('info.point'));
    }
  };

  const citizenshipCheckbox = withHI ? null : (
    <Checkbox
      className={cn(styles.checkbox, { [styles.checkbox__error]: form.getInputProps('info.has_citizenship').error })}
      checked={form.values.info.has_citizenship}
      onChange={() => form.setFieldValue('info.has_citizenship', !form.values.info.has_citizenship)}
      label='Я являюсь гражданином РФ'
    />
  );

  return {
    status,
    internet: true,
    value: SECTIONS_NAMES[Sections.info],
    control: (
      <Control
        field={SECTIONS_NAMES[Sections.info]}
        filled={status === ItemStatus.FILLED}
        disabled={status === ItemStatus.DISABLED}
      />
    ),
    panel: (
      <div className={styles.info__container}>
        <div className={styles.info__description}>
          {withHI
            ? CONSTANTS.info.label.withHI
            : form.values.delivery === 'true'
              ? CONSTANTS.info.label.delivery
              : CONSTANTS.info.label.point}
        </div>
        <TextInput
          placeholder={CONSTANTS.info.name.placeholder}
          label={CONSTANTS.info.name.label}
          maxLength={50}
          withValidation
          {...form.getInputProps('info.name')}
          mask={/^[^\d]+$/}
        />
        <div className={styles.info__inputs}>
          <PhoneInput
            label={CONSTANTS.info.phoneLabel}
            inputRef={inputRef}
            {...form.getInputProps('info.contact_phone')}
          />
          <EmailInput
            maxLength={50}
            label={CONSTANTS.info.emailLabel}
            withoutValidation
            {...form.getInputProps('info.email')}
          />
        </div>
        {form.values.delivery === 'true' || withHI ? (
          <>
            <div className={styles.info__inputs}>
              <div className={cn(styles.address, { [styles.addressWithHI]: withHI })}>
                <TextInput
                  label={withHI ? CONSTANTS.info.address.hi_label : CONSTANTS.info.address.label}
                  placeholder={CONSTANTS.info.address.placeholder}
                  withValidation
                  maxLength={50}
                  {...form.getInputProps('info.address')}
                />
              </div>
              {withHI && (
                <div className={styles.recommended}>
                  <PhoneInput
                    labelStyles={styles.phoneLabelInput}
                    disabled={!!whoRecommended.length}
                    label={
                      <>
                        {CONSTANTS.info.whoRecommended}
                        <Tooltip
                          classNames={{
                            tooltip: cn(styles.tooltip),
                          }}
                          label={<RichText whiteText>{CONSTANTS.info.whoRecommendedTooltip}</RichText>}
                        >
                          <div className={styles.infoIcon}>
                            <Info />
                          </div>
                        </Tooltip>
                      </>
                    }
                    {...form.getInputProps('info.whoRecommended')}
                  />
                </div>
              )}
            </div>
            {citizenshipCheckbox}
            <div>
              <div className={cn(styles.control__title, styles.control__title__time)}>
                {CONSTANTS.RADIOS.exactTime.control}
              </div>
              <RadioGroup {...form.getInputProps('info.exactTime')} items={CONSTANTS.RADIOS.exactTime.items} />
            </div>
            {form.values.info.exactTime === 'true' && (
              <div onClick={open}>
                <TextInput
                  value={
                    form.values.info.date
                      ? format(form.values.info.date, 'dd.MM.y с HH:mm') +
                        format(addHours(form.values.info.date, 1), ' до HH:mm')
                      : ''
                  }
                  rightSection={<TimeIcon />}
                  style={{ pointerEvents: 'none' }}
                />
              </div>
            )}
          </>
        ) : (
          <div className={styles.map}> 
            {citizenshipCheckbox}
            <div className={styles.control__title}>{CONSTANTS.map.label}</div>
            <SalesPointsMap isForm isError={form.getInputProps('info.point').error} onSelect={setAddress} />
            {form.getInputProps('info.point').error ? (
              <span className={cn(styles.error, styles.error__map)}>{form.getInputProps('info.point').error}</span>
            ) : (
              <SelectedPoint selectedPoint={form.values.info.point} />
            )}
          </div>
        )}
        <div className={styles.info__footer}>
          <Button
            className={cn(styles.button, !form.isValid(SECTIONS_NAMES[Sections.info]) && styles.button__invalid)}
            onClick={next}
          >
            Далее
          </Button>
          <p>
            {withHI
              ? CONSTANTS.info.label.withHIClue
              : form.values.delivery === 'true'
                ? CONSTANTS.info.label.deliveryClue
                : CONSTANTS.info.label.pointClue}
          </p>
        </div>
      </div>
    ) 
  };
};

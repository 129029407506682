import { ReactNode } from 'react';
import { Button } from '@mantine/core';
import cn from 'classnames';
import { Control, SelectedPoint } from '@/shared/ui-kit/form';
import { ItemStatus } from '@/shared/ui-kit/form/accordion';
import { SELECTED_ICON, SelectedItem } from '@/shared/ui-kit/form/selected-item';
import { storage } from '@/shared/lib/storage';
import { reachGoal } from '@/shared/lib/ym';
import { YM } from '@/shared/constants/yandex-metrika';
import { ORDER_PARAMS } from '@/modules/tariff/shared/requests/constants';
import { useSendSmsMutation } from '@/store/api/sms-auth/sms-auth.api';
import { CONSTANTS, SectionDataType, SectionProps, Sections, SECTIONS_NAMES } from '../constants';
import styles from '../form.module.scss';

interface ConfirmationSectionProps extends SectionProps {
  withoutSms?: boolean;
  confirmPanel: ReactNode;
  footer?: ReactNode;
}

export const ConfirmationSection = ({
  form,
  status,
  withHI,
  withoutSms,
  confirmPanel,
  setOpenedSection,
  footer
}: ConfirmationSectionProps): SectionDataType => {
  const [sendSms] = useSendSmsMutation();

  const onConfirm = () => {
    form.setFieldValue('confirmation', true);
    setOpenedSection?.(SECTIONS_NAMES[Sections.sms]);
    reachGoal(YM.click_cart_next_step3);
    const storageObj = storage.get('contact_phone');
    const parsedStorageObj = storageObj && JSON.parse(storageObj);
    const isNotSamePhone = parsedStorageObj && parsedStorageObj?.contact_phone === form.values.info.contact_phone;
    const timeIsExpired = parsedStorageObj && parsedStorageObj?.expirationTime > Date.now();

    if (!storageObj || isNotSamePhone || timeIsExpired) {
      sendSms({ contact_phone: form.values.info.contact_phone, source_id: ORDER_PARAMS.SOURCE_ID });
      const sessionObject = {
        contact_phone: form.values.info.contact_phone,
        expirationTime: Date.now() + 95000,
      };
      storage.set('contact_phone', JSON.stringify(sessionObject));
    }
  };

  return {
    status,
    internet: true,
    value: SECTIONS_NAMES[Sections.confirmation],
    control: (
      <Control
        field={SECTIONS_NAMES[Sections.confirmation]}
        filled={status === ItemStatus.FILLED}
        disabled={status === ItemStatus.DISABLED}
      />
    ),
    panel: (
      <>
        <div className={styles.confirm__wrapper}>{confirmPanel}</div>
        {form.values.delivery === 'true' ? (
          <SelectedItem
            icon={SELECTED_ICON.DELIVERY}
            label={CONSTANTS.confirmation.delivery}
            value={form.values.info.address}
            confirmation
          />
        ) : (
          <SelectedPoint confirmation selectedPoint={form.values.info.point} />
        )}
        {!withHI && (
          <SelectedItem
            icon={SELECTED_ICON.PAYMENT}
            label={CONSTANTS.confirmation.online /*[form.values.payment as keyof typeof CONSTANTS.confirmation]*/}
            confirmation
          />
        )}
        {withHI && (
          <SelectedItem
            icon={SELECTED_ICON.CONNECTION}
            label={CONSTANTS.confirmation.connection}
            value={form.values.info.address}
            confirmation
          />
        )}
        {!withoutSms ? (
          <Button className={cn(styles.button, styles.confirm__button)} onClick={onConfirm}>
            Подтвердить
          </Button>
        ) : (
          footer
        )}
      </>
    )
  }
};

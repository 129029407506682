import { Control, RadioGroup } from '@/shared/ui-kit/form';
import { ItemStatus } from '@/shared/ui-kit/form/accordion';
import { CONSTANTS, SectionDataType, SectionProps, Sections, SECTIONS_NAMES } from '../constants';

export const DeliverySection = ({ form, status }: SectionProps): SectionDataType => ({
  status,
  value: SECTIONS_NAMES[Sections.delivery],
  control: (
    <Control
      field={SECTIONS_NAMES[Sections.delivery]}
      fieldValue={form.values.delivery}
      filled={status === ItemStatus.FILLED}
      disabled={status === ItemStatus.DISABLED}
    />
  ),
  panel: (
    <RadioGroup
      {...form.getInputProps(SECTIONS_NAMES[Sections.delivery])}
      items={CONSTANTS.RADIOS.delivery.items}
    />
  ),
});
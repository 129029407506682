import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from '@/shared/constants/links';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { ReferralData, ReferralResponse } from './referral-system.type';

export const referralSystemApi = createApi({
  reducerPath: 'inviteFriendApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.referralSystem),

  endpoints: (builder) => ({
    createReferralLink: builder.mutation<ReferralResponse, ReferralData>({
      query: (data) => ({
        url: '/referral-link',
        method: 'POST',
        body: data,
      }),
    }),

    recordReferralTrack: builder.mutation<ReferralResponse, ReferralData>({
      query: (data) => ({
        url: '/referral-track',
        method: 'POST',
        body: data,
      }),
    }),

    recordReferralRequestTrack: builder.mutation<ReferralResponse, ReferralData>({
      query: (data) => ({
        url: '/request-track',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useCreateReferralLinkMutation,
  useRecordReferralTrackMutation,
  useRecordReferralRequestTrackMutation,
} = referralSystemApi;
